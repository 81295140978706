import * as Sentry from '@sentry/sveltekit';

import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';

// Sentry.init({
// 	dsn: 'https://a2d6539441cb499f8d8ec674e11552a5@o1266901.ingest.sentry.io/4504763398094848',
// 	tracesSampleRate: dev ? 0 : 0.5,
// 	environment: dev ? 'development' : 'production'
// });

// const myErrorHandler: HandleClientError = ({ error, event }) => {
// 	console.error('An error occurred on the client side:', error, event);
// };

// This will catch errors in load functions from +page.ts files
// export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://a2d6539441cb499f8d8ec674e11552a5@o1266901.ingest.sentry.io/4504763398094848',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()],
	enabled: dev ? false : true
});

const myErrorHandler = ({ error, event }: { error: any; event: any }) => {
	console.log('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler) satisfies HandleClientError;
