import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about-us": [17],
		"/auth/login": [18],
		"/contact": [19],
		"/contul-meu/anunturi-salvate": [20],
		"/contul-meu/cautarile-mele": [21],
		"/cookies": [22],
		"/faq": [23],
		"/pentru-agentii": [24],
		"/search": [25],
		"/terms-and-conditions": [26],
		"/user/unsubscribe": [27],
		"/[city]": [3],
		"/[city]/ansambluri-rezidentiale": [13],
		"/[city]/ansambluri-rezidentiale/[ansamblu]": [14],
		"/[city]/imobiliare-[id]": [15],
		"/[city]/strazi-[index]": [16],
		"/[city]/[transaction=transactionMatcher]": [11],
		"/[city]/[transaction=transactionMatcher]/[...dynamicResultsPlaceRoomsOrStreet]": [12],
		"/[city]/[place]": [4],
		"/[city]/[place]/ansambluri-rezidentiale": [7],
		"/[city]/[place]/ansambluri-rezidentiale/[ansamblu]": [8],
		"/[city]/[place]/imobiliare-[id]": [9],
		"/[city]/[place]/strazi-[index]": [10],
		"/[city]/[place]/[poi]": [5],
		"/[city]/[place]/[poi]/ansambluri-rezidentiale": [6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';